import { useEffect, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection
} from 'lexical';

type ParagraphPlaceholderPluginProps = {
  placeholder: string;
};

const tailwindPlaceholderClasses = [
  'before:text-npl-text-icon-on-light-surface-tertiary',
  'before:content-[attr(data-placeholder)]',
  'before:pointer-events-none',
  'before:float-left',
  'before:h-0'
];

export const ParagraphPlaceholderPlugin = ({
  placeholder
}: ParagraphPlaceholderPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const paragraphRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState }) => {
        const nativeSelection = window.getSelection();

        editorState.read(() => {
          // Cleanup
          if (paragraphRef?.current) {
            paragraphRef.current.removeAttribute('data-placeholder');
            paragraphRef.current.classList.remove(
              ...tailwindPlaceholderClasses
            );
            paragraphRef.current = null;
          }

          const selection = $getSelection();

          if (
            !nativeSelection ||
            !selection ||
            !$isRangeSelection(selection)
          ) {
            return;
          }

          const parentNode = selection.anchor.getNode();

          if (!$isParagraphNode(parentNode) || !parentNode.isEmpty()) {
            return;
          }

          // It's a paragraph node, it's empty, and it's selected

          // Now switch over to the native selection to get the paragraph DOM element

          const paragraphDOMElement = nativeSelection.anchorNode;

          if (!paragraphDOMElement) {
            return;
          }

          if (paragraphDOMElement instanceof HTMLParagraphElement) {
            paragraphRef.current = paragraphDOMElement;
            paragraphRef.current.setAttribute(
              'data-placeholder',
              placeholder
            );
            paragraphRef.current.classList.add(
              ...tailwindPlaceholderClasses
            );
          }
        });
      }
    );

    return () => {
      removeUpdateListener();
    };
  }, [editor, placeholder]);

  return null;
};
